<template>
  <div v-if="isLoaded" class="msg-container">
    <div class="contacts">
      <div
        class="search-bar"
        @focusin="toggleIsSearching(true)"
        @focusout="toggleIsSearching(false)"
      >
        <div class="search-btn">
          <ion-icon name="search-outline" class="small-icon"></ion-icon>
        </div>

        <input
          type="text"
          name="search"
          placeholder="Search names, chats, etc."
          v-model="search"
        />
      </div>
      <ul
        class="search-results"
        v-show="isSearching && search != '' && searchAllProfile.length > 0"
      >
        <ContactCard
          v-for="(person, index) in searchAllProfile"
          :class="{ 'first-item': index == 0 && false }"
          :key="person.id"
          :contact="{ ...person, chatterName: person.name }"
          @click="handleClickSearch(person.id)"
        />
      </ul>
      <ul class="contact-list">
        <ContactCard
          v-for="(contact, index) in contacts"
          :class="{
            'first-item': index == 0 && false,
            'unread': contact.unreadNotice[contact.position],
            'selectedChat': activeChat == contact.id,
          }"
          :key="contact.id"
          :contact="contact"
          @click="
            repullChat(
              contact.id,
              contact.position,
              contact.chatterUid,
              contact.unreadNotice
            );
            activeChat = contact.id;
          "
        />
      </ul>
    </div>
    <ChatBlock
      :firstClick="firstClick"
      :chatData="chatData"
      :senderPosition="senderPosition"
      :chatId="chatId"
      :chatterId="chatterId"
    />
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import getContacts from "@/composables/Msg/getContacts";
import toggleUnread from "@/composables/Msg/toggleUnread";
import getChat from "@/composables/Msg/getChat";
import getProfile from "@/composables/Profile/getProfile";
import getAllProfile from "@/composables/Profile/getAllProfile";
import createNewChat from "@/composables/Msg/createNewChat";
import ContactCard from "@/components/Messages/contactCard.vue";
import ChatBlock from "@/components/Messages/chatBlock.vue";
import { ref, onMounted, watch, watchEffect, onUnmounted } from "vue";

export default {
  components: {
    ContactCard,
    ChatBlock,
  },
  props: ["targetChatterId"],
  emits: ["change-target-chatter"],
  async setup(props, { emit }) {
    const { user } = getUser();
    const isSearching = ref(false);
    const contacts = ref(null);
    const chatData = ref(null);
    const chatId = ref(null);
    const chatterId = ref(null);
    const firstClick = ref(true);
    const senderPosition = ref(null);
    const myProfile = ref(null);
    const allProfile = ref(null);
    const searchAllProfile = ref(null);
    const search = ref("");
    const noSpecial = ref(null);
    const isLoaded = ref(false);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const activeChat = ref(null);

    onMounted(async () => {
      await getProfile(myProfile, user.value.uid);
      await getAllProfile(allProfile);
      await getContacts.getContacts(contacts, isLoaded, user.value.uid, activeChat);

      watch(isLoaded, async ()=>{
        setTimeout(async () => {
          if(props.targetChatterId){
            let targetChat
            contacts.value.forEach(contact => {
              if(contact.chatterUid == props.targetChatterId){
                targetChat = contact
              }
            })
            await repullChat(
              targetChat.id,
              targetChat.position,
              targetChat.chatterUid,
              targetChat.unreadNotice
            );
            activeChat.value = targetChat.id;
            emit("change-target-chatter", null);
          }
        }, 1000);
      })

      watchEffect(() => {
        searchAllProfile.value = allProfile.value;

        searchAllProfile.value = searchAllProfile.value.filter((person) => {
          if (search.value == "") {
            return false;
          } else if (noSpecial.value.test(search.value)) {
            alert("no specials");
            search.value = "";
            return false;
          }
          return (
            person.name.toLowerCase().match(search.value.toLowerCase()) &&
            person.id != user.value.uid
          );
        });

        searchAllProfile.value = searchAllProfile.value.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      });
    });

    onUnmounted(async () => {
      await getContacts.removeListener()
    });

    const repullChat = async (chatID, position, chatterUid, unreadNotice) => {
      // console.log("repullChat");
      firstClick.value = false;
      senderPosition.value = position;
      await getChat(chatData, chatID);
      if(unreadNotice[position]){
        await toggleUnread(chatID, position, unreadNotice);
      }
      chatId.value = chatID;
      chatterId.value = chatterUid;
    };

    const handleClickSearch = async (personUid) => {
      let flag = false;
      contacts.value.forEach((contact) => {
        if (contact.chatterUid == personUid) {
          flag = true;
          repullChat(contact.id, contact.position, contact.chatterUid, contact.unreadNotice);
        }
      });
      if (!flag) {
        await createNewChat(personUid, user.value.uid);
      }
    };

    const toggleIsSearching = (status) => {
      if (status) {
        isSearching.value = true;
      } else {
        setTimeout(() => {
          isSearching.value = false;
        }, 500);
      }
      // console.log(search.value);
    };

    return {
      isLoaded,
      isSearching,
      contacts,
      chatData,
      firstClick,
      chatId,
      activeChat,
      chatterId,
      senderPosition,
      search,
      allProfile,
      searchAllProfile,
      repullChat,
      handleClickSearch,
      toggleIsSearching,
    };
  },
};
</script>

<style scoped>
.msg-container {
  display: flex;
  width: 100%;
  height: 100vh;
  animation: fadein 1s;
}

.first-item {
  border-top: none;
}

.contacts {
  position: relative;
  height: 100vh;
  flex: 0 0 30%;
  padding: 3vh 2vw 0 2vw;
}

.contact-list {
  padding-bottom: 3vh;
  height: 89vh;
  overflow-y: scroll;
  list-style: none;
  margin-top: 1.5vw;
}

.contact-list::-webkit-scrollbar {
  display: none;
}

.search-bar {
  margin: 1vh 0;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.search-bar div {
  flex: 0 1 10%;
  color: #808080;
  font-size: 80%;
  padding: auto;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 0 0 80%;
  color: #808080;
  height: 4vh;
  background-color: white;
  border: none;
  outline: none;
}

.search-results {
  position: absolute;
  z-index: 10;
  top: 9vh;
  left: 2vw;
  padding: 0 0;
  border-radius: 10px;
  max-height: 50vh;
  width: 80%;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.search-results::-webkit-scrollbar {
  display: none;
}

.search-button {
  background-color: #cca2d8;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 3vh;
  color: white;
  cursor: pointer;
}

.selectedChat {
  background-color: #cecece;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .msg-container {
    display: block;
  }

  .contacts {
    height: auto;
  }

  .contact-list {
    height: auto;
  }
}
</style>
